<template>
  <b-row>
    <!-- --------------------------------------------------------- 
    Sales Summary
    --------------------------------------------------------- -->
    <b-col cols="12">
      <b-card class="mb-4" no-body>
        <SalesSummery v-if="[1,2].includes(parseInt(authUserType)) && dashboardReady" :stats="stats"></SalesSummery>
      </b-card>
    </b-col>
    <!-- --------------------------------------------------------- 
    Email Campaigns
    --------------------------------------------------------- -->
    <b-col lg="12" cols="12" md="12">
      <b-card class="mb-4">
        <EmailCampaign v-if="[1,2].includes(parseInt(authUserType)) && dashboardReady" :stats="stats"></EmailCampaign>
      </b-card>
    </b-col>
    <!-- --------------------------------------------------------- 
    Active Visitors
    --------------------------------------------------------- -->
    <b-col v-if="parseInt(authUserType) === 4 && dashboardReady" lg="12" cols="12" md="12">
      <b-card class="mb-4">
        <ActiveVisitors></ActiveVisitors>
      </b-card>
    </b-col>
    <!-- --------------------------------------------------------- 
    Projects of the Month
    --------------------------------------------------------- -->
    <b-col v-if="parseInt(authUserType) === 1 && dashboardReady" cols="12">
      <b-card class="mb-4">
        <!-- Header -->
        <div class="d-md-flex align-items-center pb-0">
          <div>
            <h4 class="card-title">Projects of the Month</h4>
            <h5 class="card-subtitle text-muted font-weight-normal">
              Overview of Latest Month
            </h5>
          </div>
          <div class="ml-auto mt-2 mt-md-0">
            <b-form-select
              :options="monthoptions1"
              v-model="month1"
            ></b-form-select>
          </div>
        </div>
        <MonthTable></MonthTable>
      </b-card>
    </b-col>
  </b-row>
</template>
<script>
// -----------------------------------------
// Import Components Here
// -----------------------------------------
import { mapActions, mapState } from "vuex";
import _ from 'lodash';
import SalesSummery from "../../components/dashboard-components/sales-summery/SalesSummery";
import EmailCampaign from "../../components/dashboard-components/email-campaign/EmailCampaign";
import ActiveVisitors from "../../components/dashboard-components/active-visitors/ActiveVisitors";
import MonthTable from "../../components/dashboard-components/month-table/MonthTable";

// -----------------------------------------
// Export Here
// -----------------------------------------
export default {
  name: "ClassicDashboard",
  data: () => ({
    dashboardReady: false,
    title: "ClassicDashboard",
    // Month Table
    month1: 0,
    monthoptions1: [
      { text: "Monthly", value: 0 },
      { text: "Daily", value: 1 },
      { text: "Weekly", value: 2 },
      { text: "Yearly", value: 3 },
    ],
    loaded: 0,
    resources: [],
    allResources: [],
    services: [],
    allServices: [],
    custOrders: [],
    vendPurOrders: [],
    vendOrders: [],
    custBookings: [],
    vendBookings: [],
    stats: {
      chartData: [],
      donutData: [],
      highlights: [],
      totalRevenue: 0,
      totalSales: 0
    }
  }),
  components: {
    SalesSummery,
    EmailCampaign,
    ActiveVisitors,
    MonthTable
  },
  computed: {
    ...mapState({
      authId: (state) => state.auth.authId,
      authUserType: (state) => state.auth.authUserType
    })
  },
  mounted() {
    this.initComp();
  },
  methods: {
    ...mapActions([
      "getResources",
      "getResourcesByUser",
      "getOrdersByUser",
      "getOrdersByResource",
      "getServices",
      "getServicesByUser",
      "getBookingsByUser",
      "getBookingsByService"
      ]),
    initComp(){
      this.getResourcesByUser({id:this.authId}).then(res => {
        this.resources = res;
        this.loaded += 1;
        this.getResources().then(res => {
          this.allResources = res;
          this.loaded += 1;
          this.getServicesByUser({id:this.authId}).then(res => {
            this.services = res;
            this.loaded += 1;
            this.getServices().then(res => {
              this.allServices = res;
              this.loaded += 1;
              if(parseInt(this.authUserType) === 2){
                this.getOrdersByUser({userid: this.authId}).then(res => {
                  this.vendPurOrders = res;
                  this.loaded += 1;
                })
              }
              if(parseInt(this.authUserType) === 2){
                this.getOrdersByUser({userid: this.authId}).then(res => {
                  this.custOrders = res;
                  this.loaded += 1;
                  this.getBookingsByUser({userid: this.authId}).then(res => {
                    this.custBookings = res;
                    this.loaded += 1;
                    this.loadStats();
                    this.dashboardReady = true;
                  })
                })
              }
              else{
                let items = [];
                this.getResourcesByUser({id:this.authId}).then(res => {
                  let vOrd = new Promise((resolve) => {
                    if(res.length > 0){
                    res.forEach((element, n) => {
                      this.getOrdersByResource({id: element.id}).then(re => {
                        if(re.length > 0){
                        items = items.concat(re);
                        if(n+1 === res.length){
                          resolve();
                        }
                        }
                        else{
                          resolve();
                        }
                      })
                    });
                    }
                    else{
                      resolve();
                    }
                  });
                  vOrd.then(() => {
                    this.vendOrders = items;
                    this.loaded += 1;
                    let vitems = [];
                    this.getServicesByUser({id:this.authId}).then(res => {
                      let vBooks = new Promise((resolve) => {
                        if(res.length > 0){
                        res.forEach((element, n) => {
                          this.getBookingsByService({id: element.id}).then(re => {
                            if(re.length > 0){
                            vitems = vitems.concat(re);
                            if(n+1 === res.length){
                              resolve();
                            }
                            }
                            else{
                              resolve();
                            }
                          })
                        });
                        }
                        else{
                          resolve();
                        }
                      });
                      vBooks.then(() => {
                        this.vendBookings = vitems;
                        this.loaded += 1;
                        this.loadStats();
                        this.dashboardReady = true;
                      });
                    })
                  });
                })
              }
            })
          })
        })
      })
    },
    loadStats(){
      this.stats.highlights = [
        {
          gridlg: 'col-lg-3',
          gridsm: 'col-md-6',
          iconcolor: 'text-warning',
          icon: 'mdi mdi-wallet', 
          textcolor: 'text-dark', 
          text: parseInt(this.authUserType) === 2? 'Purchased Orders':'Confirmed Orders', 
          money: parseInt(this.authUserType) === 2? this.getCustConfirmOrdersTotal():this.getVendConfirmOrdersTotal()
        },
        {
          gridlg: 'col-lg-3',
          gridsm: 'col-md-6',
          iconcolor: "text-info",
          icon: "mdi mdi-star-circle",
          textcolor: "text-dark", 
          text: parseInt(this.authUserType) === 2? 'Negotiating Orders':'Upcoming Orders', 
          money: parseInt(this.authUserType) === 2? this.getCustPendingOrdersTotal():this.getVendPendingOrdersTotal()
        },
        {
          gridlg: 'col-lg-3',
          gridsm: 'col-md-6',
          iconcolor: 'text-warning',
          icon: 'mdi mdi-wallet', 
          textcolor: 'text-dark', 
          text: parseInt(this.authUserType) === 2? 'Booked Services':'Confirmed Bookings', 
          money: parseInt(this.authUserType) === 2? this.getCustConfirmBookingsTotal():this.getVendConfirmBookingsTotal()
        },
        {
          gridlg: 'col-lg-3',
          gridsm: 'col-md-6',
          iconcolor: "text-info",
          icon: "mdi mdi-star-circle",
          textcolor: "text-dark", 
          text: parseInt(this.authUserType) === 3? 'Negotiating Services':'Upcoming Bookings', 
          money: parseInt(this.authUserType) === 3? this.getCustPendingBookingsTotal():this.getVendPendingBookingsTotal()
        }
      ];
      this.stats.totalRevenue = this.getVendConfirmOrdersTotal() + this.getVendConfirmBookingsTotal();
      this.stats.totalSales = this.getTotalSales();
      this.stats.chartData = parseInt(this.authUserType)===3? this.getCustSalesData():this.getVendSalesData();
      this.stats.donutData = [this.getOwnedResources(),this.getRentedResources(),1,1]
    },
    getCustConfirmOrdersTotal(){
      let data = this.custOrders.filter(o => parseInt(o.payment_status) !== 0)
      return _.sum(data.map(d => { return parseInt(d.offerred_price) }))
    },
    getCustPendingOrdersTotal(){
      let data = this.custOrders.filter(o => parseInt(o.payment_status) === 0)
      return _.sum(data.map(d => { return parseInt(d.offerred_price) }))
    },
    getVendConfirmOrdersTotal(){
      let data = this.vendOrders.filter(o => parseInt(o.payment_status) !== 0)
      return _.sum(data.map(d => { return parseInt(d.offerred_price) }))
    },
    getVendPendingOrdersTotal(){
      let data = this.vendOrders.filter(o => parseInt(o.payment_status) === 0)
      return _.sum(data.map(d => { return parseInt(d.offerred_price) }))
    },
    getCustConfirmBookingsTotal(){
      let data = this.custBookings.filter(o => parseInt(o.payment_status) !== 0)
      return _.sum(data.map(d => { return parseInt(d.offered_price) }))
    },
    getCustPendingBookingsTotal(){
      let data = this.custBookings.filter(o => parseInt(o.payment_status) === 0)
      return _.sum(data.map(d => { return parseInt(d.offered_price) }))
    },
    getVendConfirmBookingsTotal(){
      let data = this.vendBookings.filter(o => parseInt(o.payment_status) !== 0)
      return _.sum(data.map(d => { return parseInt(d.offered_price) }))
    },
    getVendPendingBookingsTotal(){
      let data = this.vendBookings.filter(o => parseInt(o.payment_status) === 0)
      return _.sum(data.map(d => { return parseInt(d.offered_price) }))
    },
    getOwnedResources(){
      return this.resources.length
    },
    getRentedResources(){
      let data = this.resources.filter(r => this.resources.map(d => { return d.resource_id }).includes(r.id));
      return data.length;
    },
    getTotalSales(){
      return this.vendOrders.length + this.vendBookings.length;
    },
    getVendSalesData(){
      return [{
          name: "Resources",
          data: this.vendOrders.map(d => { return parseInt(d.offerred_price) }),
        },
        {
          name: "Services",
          data: this.vendBookings.map(d => { return parseInt(d.offered_price) }),
        }]
    },
    getCustSalesData(){
      return [{
          name: "Resources",
          data: this.custOrders.map(d => { return parseInt(d.offerred_price) }),
        },
        {
          name: "Services",
          data: this.custBookings.map(d => { return parseInt(d.offered_price) }),
        }]
    }
  }
};
</script>